import * as React from 'react';
import { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import BrandLeadText from 'common/layouts/BrandLeadText';
import { ContentThemeConsumer } from 'common/contexts/ContentThemeContext';
import Meta from 'common/components/Meta';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import { pushOtherPageLoad } from 'utils/dataLayer';
import { ThemeConsumer } from 'styled-components';
import { LeadTextProps } from '../../common/layouts/LeadText/LeadText';
import { MetaProps } from '../../common/components/Meta/Meta';
import { BrandResourceState } from './interfaces';

export type BrandStateProps = LeadTextProps & MetaProps;

class Brand extends Component<BrandStateProps> {
	public componentDidMount() {
		pushOtherPageLoad();
	}

	public render() {
		const { canonicalUrl, title, description, image, ...rest } = this.props;
		return (
			<>
				<Meta canonicalUrl={canonicalUrl} title={title} description={description} image={image} />
				<ThemeConsumer>
					{theme => (
						<ContentThemeConsumer>
							{contentTheme => (
								<BrandLeadText
									showContentFamily={false}
									backgroundColor={contentTheme.color}
									textColor={theme.colors.white}
									{...rest}
								/>
							)}
						</ContentThemeConsumer>
					)}
				</ThemeConsumer>

				<WidgetZone name="BottomFullWidth" isFullWidth={true} />
			</>
		);
	}
}

const mapStateToProps: MapStateToProps<BrandStateProps, {}, State> = ({ resource }: State): BrandStateProps => {
	const { content, title, heroImageDesktop, canonicalUrl, topics, highlightIcon } = resource as BrandResourceState;
	const { leadText } = content;
	return {
		title,
		image: heroImageDesktop,
		canonicalUrl,
		description: leadText,
		topics,
		highlightIcon,
	};
};

export default connect(mapStateToProps)(Brand);
