import * as React from 'react';
import { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import LeadText from 'common/layouts/LeadText';
import Meta from 'common/components/Meta';
import { ProductGroupResourceState } from './interfaces';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import { pushOtherPageLoad } from 'utils/dataLayer';
import { LeadTextProps } from '../../common/layouts/LeadText/LeadText';
import { MetaProps } from '../../common/components/Meta/Meta';

export type ProductGroupStateProps = LeadTextProps & MetaProps;

class ProductGroup extends Component<ProductGroupStateProps> {
	public componentDidMount() {
		pushOtherPageLoad();
	}

	public render() {
		const { canonicalUrl, title, description, image, ...rest } = this.props;
		return (
			<>
				<Meta canonicalUrl={canonicalUrl} title={title} description={description} image={image} />
				<LeadText showContentFamily={false} {...rest} />
				<WidgetZone name="BottomFullWidth" isFullWidth={true} />
			</>
		);
	}
}

const mapStateToProps: MapStateToProps<ProductGroupStateProps, {}, State> = ({
	resource,
}: State): ProductGroupStateProps => {
	const { content, title, heroImageDesktop, canonicalUrl, topics } = resource as ProductGroupResourceState;
	const { leadText } = content;
	return {
		title,
		image: heroImageDesktop,
		canonicalUrl,
		description: leadText,
		topics,
	};
};

export default connect(mapStateToProps)(ProductGroup);
