import * as React from 'react';
import { Component, Fragment } from 'react';
import loadable from '@loadable/component';
import { MapStateToProps, connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Helmet } from 'react-helmet';
import Hero from 'styleguide/components/HeroBanners/Hero';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import { getHeroMediaSource } from 'utils/media';
import injectRoutable, { InjectedRoutableProps } from 'common/components/Routing/Routable';
import { ContentWrapper } from 'common/components/General';
import ContentCard from 'common/components/General/ContentCard';
import KitchenMenuHeader from './components/KitchenMenuHeader';
const WeeklyMenu = loadable(() =>
	import(/* webpackChunkName: "weeklymenu" */ 'common/components/WeeklyMenu/WeeklyMenu')
);
import SocialLinks from 'common/components/SocialLinks/SocialLinks';
import { WeeklyMenuState } from 'common/components/WeeklyMenu/interfaces';
import { RoutingState } from 'common/components/Routing/reducers';
import { State } from 'common/reducers';
import { KitchenMenuState, kitchenMenuActions } from './reducers';
import { filterNonNull } from 'utils/array';
import { getSiteUrlPrefixOrDefault, getSiteUrlId } from 'common/components/App/services';
import { pushOtherPageLoad } from 'utils/dataLayer';
import WidgetZone from 'common/components/Widgets/WidgetZone';

const KitchenMenuContentWrapper = styled(ContentWrapper)`
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(4)};
`;

const HeroTitleWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1200px;
	margin: 0 auto;
`;

const HeroTitle = styled.h1`
	${props => props.theme.typography.heading};
	font-size: 40px;
	color: ${props => props.theme.colors.white};
	margin: 0;
	position: absolute;
	left: 50%;
	bottom: 80px;
	text-align: center;
	transform: translateX(-50%);
	text-transform: uppercase;
	width: 100%;
	padding: 0 10px;

	${media.tablet`
		font-size: 60px;
	`};
`;

interface YearWeek {
	year: number;
	weekNumber: number;
}

type KitchenMenuProps = KitchenMenuStateProps &
	KitchenMenuDispatchProps &
	InjectedRoutableProps &
	WrappedComponentProps;

class KitchenMenu extends Component<KitchenMenuProps> {
	public componentDidMount() {
		const query = this.getQueryYearWeek();
		const data = this.getDataYearWeek();
		if (query && data && (query.year !== data.year || query.weekNumber !== data.weekNumber)) {
			const { siteUrlId } = this.props;
			this.props.requestWeeklyMenu(siteUrlId!, query.year, query.weekNumber);
		}
		pushOtherPageLoad();
	}

	public render() {
		const {
			weeklyMenu,
			intl,
			siteUrlPrefix,
			heroImageDesktop,
			heroImageMobile,
			heroImageOffsetY,
			heroMediaType,
		} = this.props;
		const maindishes =
			weeklyMenu && weeklyMenu.weeklyMenu ? filterNonNull(weeklyMenu.weeklyMenu.days.map(d => d.maindish)) : [];
		const firstMaindish = maindishes.length >= 1 ? maindishes[0] : undefined;
		const heroMedia = getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, 'kitchen-menu-hero');
		return (
			<Fragment>
				<Helmet>
					<meta name="description" content={this.props.intl.formatMessage({ id: 'kitchenmenu_weekly_menu_meta' })} />
				</Helmet>

				<Hero size="small" src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY}>
					<HeroTitleWrapper>
						<HeroTitle>
							<FormattedMessage id="kitchenmenu_weekly_menu" />
						</HeroTitle>
					</HeroTitleWrapper>
				</Hero>

				<ContentCard />

				<KitchenMenuHeader />

				<KitchenMenuContentWrapper>
					{weeklyMenu && (
						<>
							<WeeklyMenu {...weeklyMenu} siteUrlPrefix={siteUrlPrefix} onGoToWeek={this.onGoToWeek} />
							<Divider size={DividerSize.full} direction={DividerDirection.horizontal} />
							<SocialLinks
								heading={intl.formatMessage({ id: 'kitchenmenu_share_weekly_menu' })}
								title={intl.formatMessage(
									{ id: 'kitchenmenu_menu_for_week' },
									{ weekNumber: weeklyMenu.weeklyMenu && weeklyMenu.weeklyMenu.weekNumber }
								)}
								imageUrl={(firstMaindish && firstMaindish.imageUrl) || ''}
							/>
							<Divider size={DividerSize.full} direction={DividerDirection.horizontal} />
						</>
					)}
				</KitchenMenuContentWrapper>

				<WidgetZone name="BottomFullWidth" isFullWidth={true} />
			</Fragment>
		);
	}

	private getDataYearWeek(): YearWeek | undefined {
		const menu = this.props.weeklyMenu ? this.props.weeklyMenu!.weeklyMenu! : undefined;
		if (!menu) {
			return undefined;
		} else {
			return { year: menu.year, weekNumber: menu.weekNumber };
		}
	}

	private getQueryYearWeek(): YearWeek | undefined {
		const query = this.props.routing.query as any;
		if (!query) {
			return undefined;
		}

		const year = query.vuosi ? parseInt(query.vuosi, 10) : new Date().getFullYear();
		const weekNumber = parseInt(query.viikko, 10);
		if (isNaN(year) || isNaN(weekNumber)) {
			return undefined;
		}

		return { year, weekNumber };
	}

	private parseWeekUrl(url: string) {
		const matches = url.match(/([0-9][0-9][0-9][0-9])\/([0-9][0-9]?)/);
		if (matches) {
			return { year: parseInt(matches[1], 10), weekNumber: parseInt(matches[2], 10) };
		} else {
			return undefined;
		}
	}

	private onGoToWeek = (url: string) => {
		const info = this.parseWeekUrl(url);
		if (info) {
			const search = `?vuosi=${info.year}&viikko=${info.weekNumber}`;
			this.props.navigateTo({ pathname: this.props.routing.pathname, search, preventScroll: true });
			this.props.requestWeeklyMenu(this.props.siteUrlId!, info.year, info.weekNumber);
		}
	};
}

interface KitchenMenuStateProps {
	weeklyMenu?: WeeklyMenuState;
	heroImageDesktop?: string;
	heroImageMobile?: string;
	heroImageOffsetY: number;
	heroMediaType?: string;
	routing: RoutingState;
	siteUrlId: string | undefined;
	siteUrlPrefix: string;
}

const mapStateToProps: MapStateToProps<KitchenMenuStateProps, {}, State> = ({
	resource,
	routing,
	app,
}): KitchenMenuStateProps => {
	const { heroImageDesktop, heroImageMobile, heroImageOffsetY, heroMediaType } = resource;
	const content = resource.content as KitchenMenuState;
	const { weeklyMenu } = content;
	const sites = (app.settings && app.settings.sites) || [];
	return {
		weeklyMenu,
		heroImageDesktop,
		heroImageMobile,
		heroImageOffsetY,
		heroMediaType,
		routing,
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		siteUrlId: getSiteUrlId(routing, sites),
	};
};

interface KitchenMenuDispatchProps {
	requestWeeklyMenu: (siteUrlId: string, year: number, weekNumber: number) => any;
}

export default connect(mapStateToProps, {
	requestWeeklyMenu: (siteUrlId: string, year: number, weekNumber: number) =>
		kitchenMenuActions.requestWeeklyMenu({ siteUrlId, year, weekNumber }),
})(injectRoutable(injectIntl(KitchenMenu)));
