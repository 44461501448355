import * as React from 'react';
import { Layouts } from 'common/layouts/types';
import { VideoContentResourceState } from './interfaces';
import Meta from 'common/components/Meta';
import Basic from 'common/layouts/Basic';
import { State } from 'common/reducers';
import { MapStateToProps, connect } from 'react-redux';
import { pushOtherPageLoad } from 'utils/dataLayer';

type SupportedLayouts = 'Basic';
const layouts: Layouts<SupportedLayouts> = {
	['Basic']: Basic,
};

class VideoContent extends React.Component<VideoContentStateProps> {
	public componentDidMount() {
		pushOtherPageLoad();
	}

	public render() {
		const { layout, ...rest } = this.props;
		const Layout = layout ? layouts[layout] : layouts.Basic;

		return (
			<>
				{this.renderMeta()}
				<Layout {...rest} />
			</>
		);
	}

	private renderMeta() {
		const { canonicalUrl, title, leadText } = this.props;
		return <Meta canonicalUrl={canonicalUrl} title={title} description={leadText} />;
	}
}

interface VideoContentStateProps {
	title?: string;
	titleHtml?: string;
	canonicalUrl: string | undefined;
	leadText?: string;
	layout?: string;
}

const mapStateToProps: MapStateToProps<VideoContentStateProps, {}, State> = ({
	resource,
}: State): VideoContentStateProps => {
	const { title, titleHtml, canonicalUrl, metaDescription, layout } = resource as VideoContentResourceState;
	return {
		title,
		titleHtml,
		canonicalUrl,
		leadText: metaDescription,
		layout,
	};
};

export default connect(mapStateToProps)(VideoContent);
