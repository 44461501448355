import * as React from 'react';
import Ingress from 'styleguide/components/Typography/Ingress/Ingress';
import Divider from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import { ContentWrapper, PageTitleWrapper } from 'common/components/General';
import { FormattedMessage } from 'react-intl';
import Link from 'common/components/Routing/Link';

const KitchenMenuHeader: React.FC<{}> = props => {
	return (
		<>
			<ContentWrapper>
				<PageTitleWrapper>
					<Ingress>
						<FormattedMessage
							id="kitchenmenu_menu_for_week_lead_text"
							values={{
								a: (msg: string) => <Link to="/uutiskirje/">{msg}</Link>,
							}}
						/>
					</Ingress>
					<Divider
						size={{ default: DividerSize.full, desktop: DividerSize.desktopIngress }}
						direction={DividerDirection.horizontal}
					/>
				</PageTitleWrapper>
			</ContentWrapper>
		</>
	);
};

export default KitchenMenuHeader;
